<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-unused-vars -->
<template>
  <el-container class="contain">
    <el-main class="main">
      <div class="login flex_start">
        <div class="login-left"></div>
        <div class="login-right">
          <div class="right-title flex-cc">
            <img class="animated fadeIn" src="./img/icon.png" />
            <h1 class="splt">智慧文旅之窗</h1>
          </div>
          <div class="right-input">
            <div>
              <el-input
                v-model="input.account"
                placeholder="请输入登录账号"
              ></el-input>
              <el-input
                type="password"
                v-model="input.password"
                placeholder="请输入登录密码"
              ></el-input>
            </div>
            <el-button
              class="right-button flex-cc"
              @keyup.enter.native="onLogin()"
              @click="onLogin()"
            >
              <p>登录</p>
            </el-button>
          </div>
        </div>
      </div>
      <div class="text-Gan">
        <p>甘/肃/省/文/化/和/旅/游/厅</p>
      </div>
    </el-main>
    <div style="position: fixed; bottom: 0; width: 100%">
      <svg
        class="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g class="parallax">
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(255,255,255,0.5"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(255,255,255,0.4)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(255,255,255,0.3)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="7"
            fill="rgba(255,255,255,0.2)"
          />
        </g>
      </svg>
    </div>
  </el-container>
</template>
<script>
import style from "./css/style.css";

// import {setToken, setRefreshToken, removeToken, removeRefreshToken} from '@/util/auth'
export default {
  components: {},
  data() {
    return {
      errorTime: 0, // 错误次数
      input: {
        // 用户名和密码
        account: "",
        password: "",
      },
      blockState: 0,

      captchaKey: "",
    };
  },
  created() {
    // this.captcha();
  },
  mounted() {
    // this.$util.textAnime();
  },
  methods: {
    // 2.登陆
    onLogin() {
      if (!this.input.account) {
        this.$message.warning("请输入用户名");
        return false;
      }
      if (!this.input.password) {
        this.$message.warning("请输入密码");
        return false;
      }
      // this.captcha()
      this.login();
    },
    login() {
      if (this.input.account !== "WLT" && this.input.password !== "WLT@123") {
        this.$message.error("用户名或密码错误");
        return;
      }
      this.$message.success("登录成功");
      setTimeout(() => {
        localStorage.setItem("userInfo", JSON.stringify(this.input));
        this.$router.replace({ path: "home" });
      }, 2000);
    },
    onPassengerBtn(e) {
      const val = this.list[e];
      this.list.splice(e, 1);
      this.list.unshift(val);
      // this.blockState = 0;
    },
  },
};
// }
</script>
<style lang="scss" scoped>
.login {
  width: 80vw;
  height: 70vh;
  background: url("./img/center.png") center/100% 100% no-repeat;
  border-radius: 20px;
  display: flex;
  .login-left {
    width: 45%;
  }
  .login-right {
    width: 55%;
    padding: 10vh 3vh;
    // background: red;
  }
  .right-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
    font-weight: bold;
    color: #2a6adb;
    img {
      margin-right: 0.5vw;
      width: 50px;
      height: 50px;
    }
  }
  .right-input {
    margin-top: 10vh;
    padding: 0 10vw;
    // el-input__inner是el-input的input类名
    & /deep/ .el-input__inner {
      margin-bottom: 1.5vh;
      // width: 25vw;
      // color:#919191;
      border: none;
      background: #f6f7fb;
      border-radius: vw(36);
    }
  }
  .right-button {
    width: 100%;
    margin-top: 5vh;
    font-size: 1vw;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 5px;
    background: linear-gradient(148deg, #3e7af2 0%, #418cd9 100%);
    border-radius: 36px;
    cursor: pointer;
  }
}
.text-Gan {
  display: flex;
  justify-content: center;
  margin-top: 27px;
  font-size: 0.8vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #97caff;
  // line-height: 30px;
  letter-spacing: 1.2vw;
}
.contain {
  width: 100vw;
  height: 100vh;
  background: url("./img/bg.jpg") center/100% 100% no-repeat;
  padding: 0;
}

.main {
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
//
//@at-root
</style>
